import {seleccionadosVar} from "../interfaces/fabrica/buscar-butaca";
import { manejarError } from "../manejadores/manejadores";

function vaciarVariable() {
    const n = seleccionadosVar.length;
    for (let i = n-1; i>=0; i--) {
        const elem = document.getElementById(seleccionadosVar[i]);
        elem.classList.remove('sel');
        seleccionadosVar.pop();
    }
    //seleccionadosVar = [];
}

async function handleModificarButaca(nro, est, peso, homo, history) {
    const sesHs = sessionStorage.getItem("pkSesionHs");
    const contenedorModif = document.querySelector('.modButInterface');
    const contenedorInsert = document.querySelector('.cambioInterface');
    const contenedorLista = document.querySelector(".listasInterface");
    //try {
        const token = sessionStorage.getItem("token");
        if (token) {
            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };

            fetch(`http://esstock.com.ar:3000/api/butaca?nroButaca=${encodeURIComponent(nro)}&idEstado=${encodeURIComponent(est)}&peso=${encodeURIComponent(peso)}&homo=${encodeURIComponent(homo)}&fechaHsSesion=${encodeURIComponent(sesHs)}`, requestOptions)
            .then(response => {
                if (!response.ok) {
                    if (response.status === 403 || response.status === 404) {
                        manejarError(history);
                    } else if (response.status === 400){
                        alert("No saltear estados, ni volver a pegar homologadas");
                    }
                }
                return response.json();
            })
            .then(data =>{
                const element = document.createElement('DIV');
                if (est==7) {
                    element.id = 'mensaje-eliminacion';
                    element.innerHTML = `${nro} BORRADA CORRECTAMENTE`;
                } else {
                    element.id = 'mensaje-modificacion';
                    element.innerHTML = `${nro} MODIFICADO CORRECTAMENTE`;
                }
                if (contenedorModif) {contenedorModif.appendChild(element)}
                else {
                    if (contenedorInsert) {contenedorInsert.appendChild(element)}
                    else {contenedorLista.appendChild(element)};
                };
                
            })
            .catch(error => {
                console.log("Error: ", error);
            })



            /*const response = await fetch(`http://esstock.com.ar:3000/api/butaca?nroButaca=${encodeURIComponent(nro)}&idEstado=${encodeURIComponent(est)}&peso=${encodeURIComponent(peso)}&homo=${encodeURIComponent(homo)}&fechaHsSesion=${encodeURIComponent(sesHs)}`, requestOptions);
            const datos = await response.json();
            if (datos !== undefined) {
                const element = document.createElement('DIV');
                element.id = 'mensaje-modificacion';
                element.innerHTML = `${nro} MODIFICADO CORRECTAMENTE`;
                if (contenedorModif) {contenedorModif.appendChild(element)}
                else {
                    if (contenedorInsert) {contenedorInsert.appendChild(element)}
                    else {contenedorLista.appendChild(element)};
                };
            }*/
        }

    /*} catch (error) {
        console.log(error);
        alert("No saltear estados, ni volver a cortada. Excepcion: borrarla");
        const element = document.createElement('DIV');
        element.id = 'mensaje-error-mod';
        element.innerHTML = `Error al modificar ${nro}`;
        if (contenedorModif) {contenedorModif.appendChild(element)}
        else {
            if (contenedorInsert) {contenedorInsert.appendChild(element)}
            else {contenedorLista.appendChild(element)};
        };
    }*/
}

function mediator(est, seEs, history) {
    if (est==3) {
        const container = document.querySelector('.cambioInterface');
        const div = document.querySelector(".insertadasInterface");
        if (div) {container.removeChild(div)};
        const mod = document.querySelector(".modButInterface");
        if (mod) {container.removeChild(mod)};
        const divInsertadas = document.createElement("DIV");
        divInsertadas.classList.add("insertadasInterface");
        container.appendChild(divInsertadas);

        seleccionadosVar.forEach(element => {
            const divFor = document.createElement("DIV");
            divFor.classList.add("divFor");
            divInsertadas.appendChild(divFor);

            divFor.innerHTML = "butaca nro: <br/><h3>" + element + "<br/>";
            //input
            const input = document.createElement('input');
            input.setAttribute('type', 'text');
            input.setAttribute('id', `${element}Input`);
            input.setAttribute('placeholder', 'PESO formato 7.01');
            divFor.appendChild(input);

            //boton
            const button = document.createElement('button');
            button.textContent = 'Modificar Insertada';
            divFor.appendChild(button);

            button.addEventListener('click', () => {
                handleModificarButaca(element, est, parseFloat(document.getElementById(`${element}Input`).value), false, history);
                divInsertadas.removeChild(divFor);
            });
        })

        //boton
        const buttonVolver = document.createElement('button');
        buttonVolver.setAttribute('id', 'volverInsertada');
        buttonVolver.textContent = 'Cancelar';
        divInsertadas.appendChild(buttonVolver);

        buttonVolver.addEventListener('click', () => {
            document.body.removeChild(container);

            //meter el anterior select estado
            const cont = document.getElementById('root');
            cont.appendChild(seEs);

            vaciarVariable();
            botonBuscarButacas();
        }); 

    } else if (est==5) {
        const container = document.querySelector('.cambioInterface');
        /*seleccionadosVar.forEach((element, i) => {
            if (element.startsWith('ss') || element.startsWith('SS')) {
                handleModificarButaca(element, est, parseFloat(0), false, history);
                seleccionadosVar.splice(i, 1);
            }
        });*/
        if (seleccionadosVar.length>0){
            const div = document.querySelector(".listasInterface");
            if (div) {container.removeChild(div)};
            const mod = document.querySelector(".modButInterface");
            if (mod) {container.removeChild(mod)};
            const divListas = document.createElement("DIV");
            divListas.classList.add("listasInterface");
            container.appendChild(divListas);

            seleccionadosVar.forEach(element => {
                const divFor = document.createElement("DIV");
                divFor.classList.add("divFor");
                divListas.appendChild(divFor);

                divFor.innerHTML = "butaca nro: <br/><h3>" + element + "<br/>";
                const selectElement = document.createElement("select");
                //A
                const optionA = document.createElement("option");
                optionA.value = "A";
                optionA.text = "A";
                selectElement.appendChild(optionA);
                //S
                const optionS = document.createElement("option");
                optionS.value = "S";
                optionS.text = "S";
                selectElement.appendChild(optionS);
                divFor.appendChild(selectElement);

                //boton
                const button = document.createElement('button');
                const ide = 'boton' + element;
                button.setAttribute('id', ide);
                button.textContent = 'Lista!';
                divFor.appendChild(button);

                button.addEventListener('click', () => {
                    if (selectElement.value=="S") {
                        handleModificarButaca(element, est, parseFloat(0), true, history);
                    } else {
                        handleModificarButaca(element, est, parseFloat(0), false, history);
                    }
                    divListas.removeChild(divFor);
                });
            });

            seleccionadosVar.forEach(element => {
                if (element.startsWith('ss') || element.startsWith('SS')) {
                    //Clickear boton
                    const ide = 'boton' + element;
                    const boton = document.getElementById(ide);
                    boton.click();
                }
            })

            //boton
            const buttonVolver = document.createElement('button');
            buttonVolver.setAttribute('id', 'volverInsertada');
            buttonVolver.textContent = 'Cancelar';
            divListas.appendChild(buttonVolver);

            buttonVolver.addEventListener('click', () => {
                document.body.removeChild(container);

                //meter el anterior select estado
                const cont = document.getElementById('root');
                cont.appendChild(seEs);

                vaciarVariable();
                botonBuscarButacas();
            });
        } else {
            document.body.removeChild(container);
            //meter el anterior select estado
            const cont = document.getElementById('root');
            cont.appendChild(seEs);
            vaciarVariable();
            botonBuscarButacas();
        }

    } else if(est>5) {
        seleccionadosVar.forEach(element => {
            handleModificarButaca(element, est, parseFloat(0), false, history);
        });
        const botonConfirmar = document.getElementById('btnModificarButaca');
        const container = document.querySelector('.modButInterface');
        container.removeChild(botonConfirmar);    
    } else {
        seleccionadosVar.forEach(element => {
            handleModificarButaca(element, est, parseFloat(0), false, history);
        })    
    }
}

export function modifButaca(history) {
    const n = seleccionadosVar.length;
    if (!n || n===0) {
        alert("Seleccionar butaca");
        return
    }

    //sacar el anterior select estado
    const seEs = document.querySelector('.todo');
    const cont = document.getElementById('root');
    cont.removeChild(seEs);

    const contenedor = document.createElement('DIV');
    contenedor.classList.add("cambioInterface");
    
    function formatearListaConSaltos(lista) {
        let resultado = '';
        for (let i = 0; i < lista.length; i++) {
            if (i > 0 && i % 3 === 0) {
                resultado += '\n';  // Agrega un salto de línea después de cada 3 elementos
            }
            resultado += lista[i] + ' - ';
        }
        return resultado.slice(0, -2);  // Elimina el espacio al final
    }

    //contenedor adentro de contenedor
    const contenedor2 = document.createElement('DIV');
    contenedor2.classList.add("modButInterface");
    contenedor2.innerHTML = `CAMBIAR ESTADO DE LAS BUTACAS NRO:<br><h3 color='#000'>${formatearListaConSaltos(seleccionadosVar)}</h3>`;
    contenedor.appendChild(contenedor2);

     // Crear el elemento select
    const selectEstado = document.createElement('select');
    selectEstado.setAttribute('name', 'selectEstado');
    selectEstado.setAttribute('id', 'selectEstado');

    contenedor2.appendChild(selectEstado);

    // 5. Crear los botones
    const btnModificarButaca = document.createElement('button');
    btnModificarButaca.setAttribute('id', 'btnModificarButaca');
    btnModificarButaca.textContent = 'Confirmar Cambios';
    contenedor2.appendChild(btnModificarButaca);

    const btnVolverModBut = document.createElement('button');
    btnVolverModBut.setAttribute('id', 'volverModBut');
    btnVolverModBut.textContent = 'Cancelar';
    contenedor2.appendChild(btnVolverModBut);

    document.body.appendChild(contenedor);

    cargarEstados('option');
    
    btnModificarButaca.addEventListener("click", ()=>{
        const est = selectEstado.value;
        if (est==="borrada"){alert("Estas seguro de borrar la butaca?")};
        mediator(parseInt(est), seEs, history);
    });

    btnVolverModBut.addEventListener("click", ()=>{
        document.body.removeChild(contenedor);

        //meter el anterior select estado
        cont.appendChild(seEs);

        vaciarVariable();
        botonBuscarButacas();
    });
};

function agregarSeleccionado(b) {
    const e = document.getElementById(b);
    if (seleccionadosVar!==undefined) {
        const i = seleccionadosVar.indexOf(b);
        if (i!==-1) {
            e.classList.remove('sel');
            seleccionadosVar.splice(i, 1);
        } else {
            seleccionadosVar.push(b);
            e.classList.add('sel');
        }
    } else {
        seleccionadosVar.push(b);
        e.classList.add('sel');
    }
    
    
}

export async function botonBuscarAS(h, tipo){
    let bol = false;
    if (tipo==="S"){bol = true};
    const token = sessionStorage.getItem("token");
    if (token) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(bol),
        };

        fetch('http://esstock.com.ar:3000/api/butaca/filtroAS', requestOptions)
        .then(response => {
            if (!response.ok) {
                if (response.status === 403 || response.status === 404) {
                    manejarError(h);
                }
            }
            return response.json();
        })
        .then(data => {
            if (data.length===0){alert("No se encontraron resultados")};
            const counts = {};
            const interfaz = document.querySelector('.buscarButacaInterface');
            const contenedorBusqueda = document.querySelector('.respuestaBusqueda');
            if (contenedorBusqueda) {interfaz.removeChild(contenedorBusqueda)};
            // Mostrar resultado
            const container = document.createElement('DIV');
            container.classList.add('respuestaBusqueda');
            interfaz.appendChild(container);
            container.innerHTML = 'RESULTADO:';
            // Mostrar los conteos
            const conteoContainer = document.createElement('DIV');
            conteoContainer.classList.add('conteoModelos');
            container.appendChild(conteoContainer);
            data.forEach(row => {
                if (row.estado.nombre!=="entregada" && row.estado.nombre!=="borrada"){

                    counts[row.modelo.nombre] = (counts[row.modelo.nombre] || 0) + 1;

                    const element = document.createElement('DIV');
                    element.id = `${row.nro}`;
                    element.classList.add('but');
                    container.appendChild(element); // Agrega el elemento a la página
                    let content = row.modelo.nombre + ", " + row.estado.nombre + ", " + row.nro + ", " + row.color.nombre ;
                    let modelo = row.modelo.nombre.replace(/\s/g, '');
                    element.classList.add(modelo);
                    element.innerHTML = content;

                    element.addEventListener("click", ()=>{
                        agregarSeleccionado(element.id);
                    });
                }
            });
            Object.keys(counts).forEach(modelo => {
                const conteoElement = document.createElement('DIV');
                conteoElement.classList.add("conteoElement");
                conteoElement.textContent = `${modelo}   ----->   ${counts[modelo]}`;
                conteoContainer.appendChild(conteoElement);
            });
        })
        .catch(error => {
            console.log("Error: ", error)
        });
    };

}

export async function botonBuscarButacas(h) {
    //funcionalidad boton buscar Butaca
    let objetoBut = {nro:null, modelo:null, estado:null, color:null};
    let nro = document.getElementById('butacaNro').value;
    objetoBut.modelo = parseInt(document.getElementById('selectModelo').value);
    objetoBut.estado = parseInt(document.getElementById('selectEstado').value);
    objetoBut.color = parseInt(document.getElementById('selectColor').value);
    if (nro!=='') {objetoBut.nro = nro};

    const token = sessionStorage.getItem("token");
    if (token) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(objetoBut),
        };

        fetch('http://esstock.com.ar:3000/api/butaca/filtro', requestOptions)
        .then(response => {
            if (!response.ok) {
                if (response.status === 403 || response.status === 404) {
                    manejarError(h);
                }
            }
            return response.json();
        })
        .then(data => {
            if (data.length===0){alert("No se encontraron resultados")};
            const counts = {};
            const interfaz = document.querySelector('.buscarButacaInterface');
            const contenedorBusqueda = document.querySelector('.respuestaBusqueda');
            if (contenedorBusqueda) {interfaz.removeChild(contenedorBusqueda)};
            // Mostrar resultado
            const container = document.createElement('DIV');
            container.classList.add('respuestaBusqueda');
            interfaz.appendChild(container);
            container.innerHTML = 'RESULTADO:';
            // Mostrar los conteos
            const conteoContainer = document.createElement('DIV');
            conteoContainer.classList.add('conteoModelos');
            container.appendChild(conteoContainer);
            data.forEach(row => {
                if (row.estado.nombre!=="entregada" && row.estado.nombre!=="borrada" && row.nro!=="butacaauxiliar"){

                    counts[row.modelo.nombre] = (counts[row.modelo.nombre] || 0) + 1;

                    const element = document.createElement('DIV');
                    element.id = `${row.nro}`;
                    element.classList.add('but');
                    container.appendChild(element); // Agrega el elemento a la página
                    let content = row.modelo.nombre + ", " + row.estado.nombre + ", " + row.nro + ", " + row.color.nombre;
                    if (row.peso!==-1){content += ", " + row.peso};
                    if (row.estado.nombre==="lista") {
                        if (row.prehomologada || row.homologada) {content += ", S"}
                        else {content += ", A"}
                    };
                    let modelo = row.modelo.nombre.replace(/\s/g, '');
                    element.classList.add(modelo);
                    element.innerHTML = content;

                    element.addEventListener("click", ()=>{
                        agregarSeleccionado(element.id);
                    });
                }
            });
            Object.keys(counts).forEach(modelo => {
                const conteoElement = document.createElement('DIV');
                conteoElement.classList.add("conteoElement");
                conteoElement.textContent = `${modelo}   ----->   ${counts[modelo]}`;
                conteoContainer.appendChild(conteoElement);
            });
        })
        .catch(error => {
            console.log("Error: ", error)
        });
    };
}

function armarRecuadros(counts){
    // Organizar datos por color
    let dataByColor = {};
    Object.keys(counts).forEach(key => {
        let [modelo, color, homologacion] = key.split('-');
        if (!dataByColor[color]) {
            dataByColor[color] = [];
        }
        dataByColor[color].push({ modelo, homologacion, count: counts[key] });
    });

    // Función de comparación personalizada
    function compare(a, b) {
        const order = ["s7", "s7 xl", "s5", "s7 sim", "s7 xl sim", "s5 sim"];
        const homologacionOrder = ["S", "A"];

        let modeloComparison = order.indexOf(a.modelo) - order.indexOf(b.modelo);
        if (modeloComparison !== 0) {
            return modeloComparison;
        }

        return homologacionOrder.indexOf(a.homologacion) - homologacionOrder.indexOf(b.homologacion);
    }

    // Crear los divs
    let container = document.querySelector('.fabricaInterface');
    let colorDivCont = document.createElement('div');
    colorDivCont.classList.add("recuadros");
    let title = document.createElement("h4");
    title.innerHTML = "Recuento de listas en stock";
    colorDivCont.appendChild(title);
    
    // Obtener y ordenar las claves del objeto dataByColor
    let sortedColors = Object.keys(dataByColor).sort((a, b) => {
        let order = ['negro', 'blanco', 'rojo'];
        return order.indexOf(a) - order.indexOf(b);
    });
    
    sortedColors.forEach(color => {
        let colorDiv = document.createElement('div');
        colorDiv.classList.add(`recuadro`);
        colorDiv.classList.add(`${color}`);

        // Ordenar los elementos en dataByColor[color] usando la función de comparación
        dataByColor[color].sort(compare);

        dataByColor[color].forEach(item => {
            let itemDiv = document.createElement('div');
            itemDiv.classList.add("inf");
            if (item.modelo.includes("sim")) {
                itemDiv.textContent = `sim${item.modelo.slice(1, -3)} -----> ${item.count}`;
            } else {
                itemDiv.textContent = `${item.homologacion}${item.modelo.slice(1)} -----> ${item.count}`;
            };
            colorDiv.appendChild(itemDiv);
        });

        colorDivCont.appendChild(colorDiv);
    });
    container.appendChild(colorDivCont);
};

export async function recuentoStockListas(h) {
    let objetoBut = {nro:null, modelo:0, estado:5, color:0};

    const token = sessionStorage.getItem("token");
    if (token) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(objetoBut),
        };

        fetch('http://esstock.com.ar:3000/api/butaca/filtro', requestOptions)
        .then(response => {
            if (!response.ok) {
                if (response.status === 403 || response.status === 404) {
                    manejarError(h);
                }
            }
            return response.json();
        })
        .then(data => {
            /*const interfaz = document.querySelector('.buscarButacaInterface');
            const contenedorBusqueda = document.querySelector('.respuestaBusqueda');
            if (contenedorBusqueda) {interfaz.removeChild(contenedorBusqueda)};
            // Mostrar resultado
            const container = document.createElement('DIV');
            container.classList.add('respuestaBusqueda');
            interfaz.appendChild(container);
            container.innerHTML = 'RESULTADO:';
            // Mostrar los conteos
            const conteoContainer = document.createElement('DIV');
            conteoContainer.classList.add('conteoModelos');
            container.appendChild(conteoContainer);*/

            let counts = {};

            data.forEach(row => {
                if (!row.prehomologada && !row.homologada) {
                    let key = `${row.modelo.nombre}-${row.color.nombre}-A`;
                    counts[key] = (counts[key] || 0) + 1;
                } else {
                    let key = `${row.modelo.nombre}-${row.color.nombre}-S`;
                    counts[key] = (counts[key] || 0) + 1;
                }
            });
            console.log(counts);
            armarRecuadros(counts);


            /*const counts = {negro: [0,0,0,0,0,0,0,0,0], rojo: [0,0,0,0,0,0,0,0,0], blanco:[0,0,0,0,0,0,0,0,0]};
            data.forEach(row => {
                //MODELO, COLOR, A/S
                //S5, A5, sim5, S7, A7, sim7, S7L, A7L, sim7L               por color
                if (!row.prehomologada && !row.homologada) {

                }
                
                let counts = {};
                counts[row.modelo.nombre] = (counts[row.modelo.nombre] || 0) + 1;
                

                const element = document.createElement('DIV');
                element.id = `${row.nro}`;
                element.classList.add('but');
                container.appendChild(element); // Agrega el elemento a la página
                let content = row.modelo.nombre + ", " + row.estado.nombre + ", " + row.nro + ", " + row.color.nombre;
                if (row.peso!==-1){content += ", " + row.peso};
                if (row.estado.nombre==="lista") {
                    if (row.prehomologada || row.homologada) {content += ", S"}
                    else {content += ", A"}
                };
                let modelo = row.modelo.nombre.replace(/\s/g, '');
                element.classList.add(modelo);
                element.innerHTML = content;

                element.addEventListener("click", ()=>{
                    agregarSeleccionado(element.id);
                });
            });
            Object.keys(counts).forEach(modelo => {
                const conteoElement = document.createElement('DIV');
                conteoElement.classList.add("conteoElement");
                conteoElement.textContent = `${modelo}   ----->   ${counts[modelo]}`;
                conteoContainer.appendChild(conteoElement);
            });*/
        })
        .catch(error => {
            console.log("Error: ", error)
        });
    };
}

export function botonCrearButaca(h) {
    //funcionalidad crear butaca
    let butacaNro = document.getElementById("butacaNro").value.toUpperCase();
    let selectModelo = document.getElementById("selectModelo").value;
    let selectColor = parseInt(document.getElementById("selectColor").value);
    const fechaHs = sessionStorage.getItem("pkSesionHs");

    if (parseInt(selectModelo)===0 || parseInt(selectColor)===0 || butacaNro==="") {
        alert("Complete todos los campos");
        return;
    }

    const token = sessionStorage.getItem("token");
    if (token) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        };

        fetch(`http://esstock.com.ar:3000/api/butaca?nroButaca=${encodeURIComponent(butacaNro)}&idModelo=${encodeURIComponent(selectModelo)}&idColor=${encodeURIComponent(selectColor)}&fechaHsSesion=${encodeURIComponent(fechaHs)}`, requestOptions)
        .then(response => {
            if (!response.ok) {
                if (response.status === 403 || response.status === 404) {
                    manejarError(h);
                } else if (response.status === 409) {
                    alert("Butaca ya existente");
                }
            }
            return response.json();
        })
        .then(data => {
            if (data.error) {return data}
            else {
                const contenedorRegistro = document.querySelector('.registrarButacaInterface');
                const element = document.createElement('DIV');
                element.id = `mensaje-correcto-registro`;
                element.innerHTML = 'Butaca nro: ' + butacaNro + ' creada.  ';
                contenedorRegistro.appendChild(element);

                document.getElementById("selectModelo").selectedIndex = 0;
                document.getElementById("selectColor").selectedIndex = 0;
            }
        })
        .catch(error => {
            console.error('Error en la petición:', error);
        });
    }
}

export async function cargarModelos(tipo, h) {
    
    if (!localStorage.models) {
        const token = sessionStorage.getItem("token");
        if (token) {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };

            fetch('http://esstock.com.ar:3000/api/modelo', requestOptions)
            .then(response => {
                if (!response.ok) {
                    if (response.status === 403 || response.status === 404) {
                        manejarError(h);
                    }
                }
                return response.json();
            })
            .then(data => {
                let modelos = [];
                data.sort((a, b) => a.id - b.id);
                data.forEach(row => {
                    modelos.push(row.nombre);
                });
                localStorage.models = modelos;
            })
            .catch(error => {
                console.error('Error en la petición:', error);
            });
        }
    } else {
        if (tipo==='option') {
            let modelos = localStorage.models.split(",");
            const container = document.getElementById('selectModelo');
            modelos.forEach(m=>{
                const option = document.createElement('OPTION');
                option.innerHTML = m;
                option.value = modelos.indexOf(m)+1;
                container.appendChild(option);     
            });
        }
    }
}

export async function cargarEstados(tipo, h) {

    if (!localStorage.states) {
        const token = sessionStorage.getItem("token");
        if (token) {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };

            fetch('http://esstock.com.ar:3000/api/estado', requestOptions)
            .then(response => {
                if (!response.ok) {
                    if (response.status === 403 || response.status === 404) {
                        manejarError(h);
                    }
                }
                return response.json();
            })
            .then(data => {
                let estados = [];
                data.sort((a, b) => a.id - b.id);
                data.forEach(row => {
                    estados.push(row.nombre);
                });
                localStorage.states = estados;
            })
            .catch(error => {
                console.error('Error en la petición:', error);
            });
        }
    } else {
        if (tipo==='option') {
            let estados = localStorage.states.split(",");
            const container = document.getElementById('selectEstado');
            estados.forEach(m=>{
                const option = document.createElement('OPTION');
                option.innerHTML = m;
                option.value = estados.indexOf(m)+1;
                container.appendChild(option);     
            });
        }
    }
}

export function volverFab() {
    vaciarVariable();
}

async function handleHomologar(nro, blanca, gris, comprador) {
    const inter = document.querySelector(".homButInterface");
    try {
        const token = sessionStorage.getItem("token");
        if (token) {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };

            const response = await fetch(`http://esstock.com.ar:3000/api/homologaciones?nroButaca=${encodeURIComponent(nro)}&eBlanca=${encodeURIComponent(blanca)}&eGris=${encodeURIComponent(gris)}&comprador=${encodeURIComponent(comprador)}`, requestOptions);
            const datos = await response.json();
            if (datos !== undefined) {
                const element = document.createElement('DIV');
                element.id = 'mensaje-modificacion';
                element.innerHTML = `${nro} HOMOLOGADA CORRECTAMENTE`;
                if (inter) {inter.appendChild(element)}
                const divFor = document.querySelector(".divFor");
                inter.removeChild(divFor);
            }
        }

    } catch (error) {
        console.log(error);
        //alert("No saltear estados, ni volver a cortada. Excepcion: borrarla");
        const element = document.createElement('DIV');
        element.id = 'mensaje-error-mod';
        element.innerHTML = `Error al homologar ${nro}`;
        if (inter) {inter.appendChild(element)};
    }
}

function homologarButaca(nro) {
    //sacar el anterior select estado
    const seEs = document.querySelector('.todo');
    const cont = document.getElementById('root');
    cont.removeChild(seEs);

    //contenedor dentro de contenedor nuevo
    const contenedor = document.createElement('DIV');
    contenedor.classList.add("homButInterface");

    const contenedor2 = document.createElement('DIV');
    contenedor2.classList.add("divFor");
    contenedor2.innerHTML = `CAMBIAR ESTADO DE LA BUTACA NRO:<br><h3 color='#000'>${nro}</h3>`;

    //crear inputs
    const input = document.createElement('input');
    input.setAttribute('type', 'text');
    input.setAttribute('id', `etiqBlanca`);
    input.setAttribute('placeholder', 'Etiqueta Blanca');
    contenedor2.appendChild(input);

    const input2 = document.createElement('input');
    input2.setAttribute('type', 'text');
    input2.setAttribute('id', `etiqGris`);
    input2.setAttribute('placeholder', 'Etiqueta Gris');
    contenedor2.appendChild(input2);

    const input3 = document.createElement('input');
    input3.setAttribute('type', 'text');
    input3.setAttribute('id', `comprador`);
    input3.setAttribute('placeholder', 'Comprador');
    contenedor2.appendChild(input3);

    // Crear los botones
    const btnHomologarButaca = document.createElement('button');
    btnHomologarButaca.setAttribute('id', 'btnHomologarButaca');
    btnHomologarButaca.textContent = 'Homologar';
    contenedor2.appendChild(btnHomologarButaca);
    contenedor.appendChild(contenedor2);

    const btnVolverModBut = document.createElement('button');
    btnVolverModBut.setAttribute('id', 'volverHomBut');
    btnVolverModBut.textContent = 'Cancelar';
    contenedor.appendChild(btnVolverModBut);

    document.body.appendChild(contenedor);
    
    // prog botones
    btnHomologarButaca.addEventListener("click", ()=>{
        const etiquetaB = document.getElementById("etiqBlanca").value;
        const etiquetaG = document.getElementById("etiqGris").value;
        const comprador = document.getElementById("comprador").value;
        handleHomologar(nro, etiquetaB, etiquetaG, comprador);
    });

    btnVolverModBut.addEventListener("click", ()=>{
        document.body.removeChild(contenedor);
        cont.appendChild(seEs);
        botonHomologarButacas();
    });
}

export function botonHomologarButacas(h) {
    let objetoBut = {nro:null, modelo:0, estado:5, color:0};
    let nro = document.getElementById('butacaNro').value;
    if (nro!==''){objetoBut.nro = nro};
    const token = sessionStorage.getItem("token");
    if (token) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(objetoBut),
        };

        fetch('http://esstock.com.ar:3000/api/butaca/filtro', requestOptions)
        .then(response => {
            if (!response.ok) {
                if (response.status === 403 || response.status === 404) {
                    manejarError(h);
                }
            }
            return response.json();
        })
        .then(data => {
            if (data.length===0){alert("No se encontraron resultados")};
            const interfaz = document.querySelector('.homologarButacaInterface');
            const contenedorBusqueda = document.querySelector('.respuestaBusqueda');
            if (contenedorBusqueda) {interfaz.removeChild(contenedorBusqueda)};
            const container = document.createElement('DIV');
            container.classList.add('respuestaBusqueda');
            interfaz.appendChild(container);
            container.innerHTML = 'RESULTADO:';
            data.forEach(row => {
                if (row.prehomologada){
                    const element = document.createElement('DIV');
                    element.id = `${row.nro}`;
                    element.classList.add('but');
                    container.appendChild(element); // Agrega el elemento a la página
                    let content = row.modelo.nombre + ", " + row.estado.nombre + ", " + row.nro ;
                    let modelo = row.modelo.nombre.replace(/\s/g, '');
                    element.classList.add(modelo); 
                    element.innerHTML = content;

                    element.addEventListener("click", ()=>{
                        homologarButaca(element.id);
                    });
                }
            });
        })
        .catch(error => {
            console.error('Error en la petición:', error);
        });
    };
}

export async function excelHomologadas() {
    try {
        const token = sessionStorage.getItem("token");
        if (token) {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/octet-stream'
                },
            };
            const response = await fetch(`http://esstock.com.ar:3000/api/excel/download`, requestOptions);
            // Verificar si la respuesta es exitosa
            if (response.ok) {
                // Convertir la respuesta a un blob
                const blob = await response.blob();
                
                // Crear un objeto URL para el blob
                const url = URL.createObjectURL(blob);
                
                // Crear un enlace para descargar el archivo
                const a = document.createElement('a');
                a.href = url;
                a.download = 'homologaciones.xls';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                
                // Liberar el objeto URL
                URL.revokeObjectURL(url);
            }
        }

    } catch (error) {
        console.log(error);
    }
}
